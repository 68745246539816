













































































































































































import Vue from 'vue';
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
interface RuleID {
  monitor: string;
  monitorType: string;
}
interface SpikeRule {
  _id: RuleID;
  enable: boolean;
  abs: number;
}
interface ConstantRule {
  _id: RuleID;
  enable: boolean;
  count: number;
}
interface VariationRule {
  _id: RuleID;
  enable: boolean;
  abs: number;
}

export default Vue.extend({
  data() {
    let spikeRules = Array<SpikeRule>();
    let spikeRuleFields = [
      {
        key: '_id',
        label: '測項',
      },
      {
        key: 'enable',
        label: '啟用',
      },
      {
        key: 'abs',
        label: '絕對值',
      },
      {
        key: 'operation',
        label: '操作',
      },
    ];
    let constantRuleFields = [
      {
        key: '_id',
        label: '測項',
      },
      {
        key: 'enable',
        label: '啟用',
      },
      {
        key: 'count',
        label: '次數',
      },
      {
        key: 'operation',
        label: '操作',
      },
    ];
    let constantRules = Array<ConstantRule>();
    let variationRules = Array<VariationRule>();
    let variationRuleFields = spikeRuleFields;
    return {
      spikeRules,
      spikeRuleFields,
      constantRules,
      constantRuleFields,
      variationRules,
      variationRuleFields,
    };
  },
  computed: {
    ...mapState('monitorTypes', ['monitorTypes']),
    ...mapState('monitors', ['monitors']),
  },
  async mounted() {
    await this.fetchMonitorTypes();
    await this.fetchMonitors();
    await this.getSpikeRules();
    await this.getConstantRules();
    await this.getVariationRules();
  },
  methods: {
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    ...mapActions('monitors', ['fetchMonitors']),

    newSpikeRule() {
      this.spikeRules.push({
        _id: {
          monitor: this.monitors[0]._id,
          monitorType: this.monitorTypes[0]._id,
        },
        enable: true,
        abs: 10,
      });
    },
    newConstantRule() {
      this.constantRules.push({
        _id: {
          monitor: this.monitors[0]._id,
          monitorType: this.monitorTypes[0]._id,
        },
        enable: true,
        count: 2,
      });
    },
    newVariationRule() {
      this.variationRules.push({
        _id: {
          monitor: this.monitors[0]._id,
          monitorType: this.monitorTypes[0]._id,
        },
        enable: true,
        abs: 10,
      });
    },
    async getSpikeRules() {
      try {
        let ret = await axios.get('/SpikeRules');
        this.spikeRules = ret.data;
      } catch (err) {
        throw new Error('failed to get spike rules');
      }
    },
    async getConstantRules() {
      try {
        let ret = await axios.get('/ConstantRules');
        this.constantRules = ret.data;
      } catch (err) {
        throw new Error('failed to get constant rules');
      }
    },
    async getVariationRules() {
      try {
        let ret = await axios.get('/VariationRules');
        this.variationRules = ret.data;
      } catch (err) {
        throw new Error('failed to get variation rules');
      }
    },
    async saveRules(url: string, rules: Array<any>) {
      let promiseList = Array<Promise<any>>();
      for (let rule of rules) {
        let p = axios.post(`${url}`, rule);
        promiseList.push(p);
      }
      let all = Promise.all(promiseList);
      try {
        await all;
        this.$bvModal.msgBoxOk('成功', { headerBgVariant: 'info' });
      } catch (err) {
        this.$bvModal.msgBoxOk(`失敗 ${err}`, {
          headerBgVariant: 'danger',
        });
        throw new Error('failed to save all rules');
      }
    },
    async deleteRule(url: string, id: RuleID, reget: () => void) {
      try {
        let ret = await axios.delete(`${url}/${id.monitor}/${id.monitorType}`);
        if (ret.status === 200) {
          this.$bvModal.msgBoxOk('成功', { headerBgVariant: 'info' });
          reget();
        } else {
          this.$bvModal.msgBoxOk(`失敗 ${ret.status} - ${ret.statusText}`, {
            headerBgVariant: 'danger',
          });
        }
      } catch (err) {
        throw new Error('failed to delete spike rule');
      }
    },
  },
});
